.nav-bar__container {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  z-index: 300;
  background-color: transparent;
}

.nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 70px;
  max-width: 1200px;
  padding: 0 24px;
  margin: 0;
  backdrop-filter: blur(10px); /* this adds the glass effect */
}

.nav-bar__brand {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 64px;
}

.nav-bar__link {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-bar__logo {
  height: 3.2rem;
  width: auto;
  max-width: 100%;
}

.nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.nav-bar__tab:last-child {
  margin-right: 0;
}

.nav-bar__tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--indigo);
  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.nav-bar__tab:hover {
  color: var(--white);
}

.nav-bar__icon {
  display: none;
}

.nav-bar__buttons {
  display: flex;
  margin-left: 24px;
}

@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar__tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar__container {
    display: none;
  }

  .nav-bar__tab {
    font-size: 1.5rem;
  }

  .nav-bar {
    height: 6.4rem;
    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
  }

  .nav-bar__logo {
    height: 3.2rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__label {
    display: none;
  }

  .nav-bar__icon {
    display: block;
    font-size: 3.2rem;
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar__tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.1rem;
    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
  }

  .nav-bar__logo {
    height: 3.2rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}
