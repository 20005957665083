.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url("https://raw.githubusercontent.com/Vortextech01/astro/main/examples/portfolio/public/assets/4EA35BA6-E9C9-44C8-943B-4FC3AD60DFC7.gif") no-repeat center center fixed; 
  padding-bottom: 3rem;
  color: var(--black);
  margin: 0 auto;
  padding: 3.2rem 6.4rem;
}

.hero-banner__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);
  width: 12.8rem;
  height: 12.8rem;
}

.hero-banner__image {
  width: 10.8rem;
  height: 10.8rem;
}

.hero-banner__image--small {
  width: 7.6rem;
  height: 7.6rem;
}

.hero-banner__headline {
  letter-spacing: -1.5px;
  margin: 2.4rem 0 0.8rem 0;
  font-size: 2.4rem;
  color: white;
}

.hero-banner__description {
  max-width: 58rem;
  text-align: center;
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  line-height: 2rem;
  color: white; /* Add this line */
}

@media (min-width: 768px) {
  .hero-banner {
    background-size: contain;
  }
}

@media (min-width: 1200px) {
  .hero-banner {
    background-size: cover;
  }
}

@media only screen and (max-width: 540px) {
  .hero-banner {
    padding: 1.6rem 0.8rem;
    background-size: cover;
  }
  
  .hero-banner__logo {
    width: 6rem;
    height: 6rem;
  }
  
  .hero-banner__image {
    width: 5rem;
    height: 5rem;
  }
  
  .hero-banner__image--small {
    width: 4rem;
    height: 4rem;
  }
}

@media only screen and (max-width: 320px) {
  .hero-banner__headline {
    font-size: 2rem;
  }
  
  .hero-banner__description {
    font-size: 1.1rem;
  }
}
